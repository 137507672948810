div.education-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

div.education-container h3.title {
  text-transform: uppercase;
}

div.education-container span {
  font-weight: 800;
}

/* PROGRESS BAR */
div.education-container div.progress-bar {
  align-self: center;
  display: flex;
  justify-content: space-between;
  gap: 1.8rem;
  border-radius: 1.5rem;
  padding: 0.5rem 1.5rem;
  margin-top: 0.8rem;
  background-color: var(--bg-less);
  background-image: linear-gradient(
    to left,
    transparent 50%,
    rgba(0, 113, 114, 0.4) 50%
  );
  background-size: 200%;
  background-position: 100%;
  transition: ease-out 0.4s;
}

div.education-container div.progress-bar:hover {
  background-position: 0%;
  transition: ease-in 0.4s;
}

div.education-container div.progress-bar p {
  font-style: italic;
}

/* TEXT AROUND CLASS CARD */
div.education-container p.subtitle {
  margin: 0.8rem 0 0.5rem 0;
  font-weight: 500;
}

/* CLASS CARD */
div.education-card {
  background-color: var(--bg-less);
  border-radius: 1.8rem;
  padding: 0.8rem 1.8rem;
}
