@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bungee&display=swap");

:root {
  --bg-color: rgb(247, 247, 247);
  --bg-less: rgba(247, 247, 247, 0.2);
  --primary-color: rgb(0, 113, 114);
  --highlight: rgb(227, 186, 53);
  --shadow: rgb(183, 153, 124, 0.8);
  --shadow-light: rgb(183, 153, 124, 0.4);
  --title-font: "Bungee", sans-serif;
}

body {
  margin: 0;
  box-sizing: border-box;
  background-color: var(--bg-color);
  font-family: "Manrope", sans-serif;
  font-size: 16.5px;
  overflow-x: hidden;
}

div#root div#app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

header,
footer {
  background-color: var(--primary-color);
  color: white;
}

header,
footer {
  padding: 0 2%;
}

main {
  flex: 3;
  width: 100%;
  display: flex;
  align-items: center;
}

a {
  text-decoration: none;
  color: black;
}

h1,
h2,
h3,
p {
  margin: 0;
  padding: 0;
}

h1 {
  line-height: 3rem;
}

h1,
h2 {
  font-family: var(--title-font);
  letter-spacing: 0.05rem;
}

.box-shadow {
  box-shadow: 2px 2px 6px var(--shadow);
}
