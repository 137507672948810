main.projects {
  padding: 2.5rem 0;
  flex-direction: column;
  width: 100%;
}

/* SIZE OF MAIN FOR CONTENT */
main.projects h1,
section.projects-container,
main.projects div.back-top {
  width: 90%;
}

main.projects h1 {
  font-size: 3rem;
  padding-bottom: 2rem;
}

/* PROJECTS CONTAINER */
section.projects-container {
  display: flex;
  justify-content: center;
  gap: 4rem;
  flex-wrap: wrap;
  width: 100%;
}

/* WHEN ON PROFILE - TOP */
main.projects div.back-top {
  display: flex;
  align-items: flex-start;
  gap: 3.5rem;
}

/* BACK BUTTON */
main.projects div.back-top button {
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  font-size: 1.3rem;
  background-color: var(--shadow);
  border: none;
  border-radius: 8px;
  padding: 0.5rem 1.5rem;
  transition: ease-in-out 0.3s;
}

main.projects div.back-top button:hover {
  background-color: var(--shadow-light);
  transition: ease-in-out 0.3s;
  border: none;
  cursor: pointer;
}
