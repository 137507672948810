main.home {
  justify-content: center;
  padding: 2rem 0;
}

/* CONTAINER */
section.container-home {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  background-color: var(--shadow);
  border-radius: 5rem;
  padding: 4rem 4.5rem;
}

/* SPAN TAG SURROUNDING NAME + B.S. */
section.container-home span {
  color: var(--primary-color);
  transition: ease-in-out 0.3s;
}

section.container-home span:hover {
  color: black;
  transition: ease-in-out 0.3s;
}

/* WELCOME + NAME/PURSUIT + BIO */
div.home-left {
  flex: 3.5;
  width: 100%;
}

div.home-left h1 {
  font-size: 3rem;
}

div.home-left h2 {
  font-size: 1.9rem;
  margin: 1.5rem 0;
}

div.home-left p {
  font-size: 1.2rem;
  margin-bottom: 0.8rem;
}

/* IMG + EDUCATION INFORMATION */
div.home-right {
  flex: 1.5;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding-left: 0.8rem;
}
