/* RESUME HEADER - NAME & EDUCATION */
div.top-resume {
  display: flex;
  align-items: center;
  gap: 2%;
}

div.top-resume h1 {
  font-size: 2.3rem;
  margin-bottom: 0.3rem;
}

div.top-resume h2 {
  font-size: 1.3rem;
}

/* TECH SKILLS */
section.technical-skills span {
  font-weight: 800;
}

section.technical-skills {
  font-size: 1.2rem;
}

/* CLASS CARDS SECTION */
div.classes-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1.2rem;
}

/* CLASS CARDS */
div.resume-section {
  flex: 1;
  background-color: var(--shadow);
  transition: ease-in-out 0.3s;
  border-radius: 0.5rem;
  padding: 0.8rem 1.1rem;
  min-width: 28rem;
}

div.resume-section:hover {
  background-color: var(--shadow-light);
  transition: ease-in-out 0.3s;
}

div.resume-section p:first-of-type {
  font-style: italic;
}

/* WORK EXPERIENCE */
div.work-experience div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

main.resume section:last-of-type {
  margin-bottom: 3rem;
}

/* GENERAL SKILLS */
section.general-skills span {
  font-style: italic;
}
