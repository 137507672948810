@media (max-width: 600px) {
  /* RESUME CLASS CARD WIDTH WHEN VIEWPORT SMALL*/
  div.resume-section {
    min-width: 90%;
  }

  /* HEADER WHEN VIEWPORT SMALL */
  header {
    padding: 0.6rem;
  }

  header ul {
    gap: 1.2rem;
  }
}

/* PDF BUTTON ON RESUME TAB */
@media (max-width: 1115px) {
  div.top-resume div.title a.button-resume {
    margin-bottom: 0.8rem;
  }
}

@media (max-width: 1215px) {
  /* FONT SIZE */
  :root {
    font-size: 14.5px;
  }

  /* HOME WRAP EARLY */
  section.container-home {
    flex-direction: column;
  }

  /* RESUME PAGE SIZE */
  div.top-resume,
  main.resume section {
    width: 90%;
  }

  /* SPACING BETWEEN EDUCATION */
  div.top-resume div.education {
    margin-bottom: 0.8rem;
  }

  /* PROJECT PROFILE WRAP EARLY */
  section.profile {
    flex-direction: column;
    align-items: center;
  }

  div.profile-left,
  div.profile-right {
    flex: 1;
    width: 100%;
  }

  div.imgs-container {
    width: 90%;
    flex: 1;
  }
}

/* GAP BETWEEN EDUCATION & DATE */
@media (max-width: 1800px) {
  div.top-resume div.title div.split {
    gap: 0.2rem;
  }
}
