main.resume {
  padding-top: 3rem;
  flex-direction: column;
}

/* GENERAL STYLING AROUND THE PAGE */
div.top-resume,
main.resume section {
  width: 80%;
}

div.title,
div.split {
  width: 100%;
}

div.split {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.5rem;
}

main.resume section h2 {
  font-size: 2rem;
  margin: 1.5rem 0 0.5rem 0;
}

/* BUTTON STYLING */
a.button-resume {
  border: 1px solid;
  border-radius: 8px;
  padding: 0.2rem 0.8rem;
  transition: ease-in-out 0.3s;
}

a.button-resume:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: white;
  transition: ease-in-out 0.3s;
}

/* SEPARATOR/LINE STYLING */
.line {
  border-bottom: 2.5px solid black;
  margin-bottom: 0.5rem;
}

/* FONT SIZES */
div.resume-section p:last-of-type, /* specific for the last p on the class cards */
div.work-experience p,
div.work-experience ul,
section.general-skills p {
  font-size: 1.15rem;
}
