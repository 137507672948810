footer {
  min-height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  box-shadow: 0px 0px 6px black;
}

footer img {
  height: 28px;
}

footer img:hover {
  cursor: pointer;
}
