main.contact-me {
  justify-content: center;
}

main.contact-me section {
  width: 90%;
  display: flex;
  justify-content: center;
}

.contact-container {
  flex-shrink: 2;
  border-radius: 5rem;
  min-height: 500px;
  width: 60%;
  min-width: 300px;
  padding: 0 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--shadow);
}

.contact-container div.find-me {
  font-size: 1.5rem;
  margin-top: 0.5rem;
  font-weight: 500;
}

.contact-container h1 {
  font-size: 3.5rem;
}

.contact-container h2 {
  font-size: 2rem;
  margin-top: 1.5rem;
}

.contact-container a.link-hover {
  color: var(--bg-color);
  transition: ease-in-out 0.3s;
}

a.link-hover:hover {
  transition: ease-in-out 0.3s;
  color: var(--highlight);
  font-weight: 600;
}
