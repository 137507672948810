header {
  min-height: 5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 0px 0px 10px black;
}

header ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

header li {
  font-weight: 800;
  font-size: 1.05rem;
  transition: ease-in-out 0.3s;
}

header li:hover {
  cursor: pointer;
  color: var(--highlight);
  transition: ease-in-out 0.3s;
}
