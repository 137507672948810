section.profile {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

section.profile h2 {
  font-size: 2.7rem;
}

/* TITLE + BUTTON WHEN BUTTON AVAILABLE */
div.profile-left div {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 0.6rem;
  justify-content: space-between;
  gap: 0.3rem;
}

/* PROJECT TOP - IMG & TEXT */
/* LEFT SIDE - IMG & LANG LINE */
div.profile-left {
  flex: 3;
}

div.profile-left img {
  margin-top: 0.5rem;
  max-width: 100%;
}

/* LANG LINE */
div.profile-left ul,
div.profile-left img {
  border-radius: 8px;
}

div.profile-left ul {
  list-style: none;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 0.5rem;
  background-color: var(--shadow);
  padding: 1rem;
}

div.profile-left ul li {
  transition: ease-in-out 0.3s;
  font-size: 1.2rem;
}

div.profile-left ul li:hover {
  color: var(--primary-color);
  font-weight: 800;
  transition: ease-in-out 0.3s;
}

/* RIGHT SIDE - OBJECTIVE & DESCRIPTION */
div.profile-right {
  flex: 2.5;
}

div.profile-right p span {
  font-style: italic;
  font-weight: 600;
}

div.profile-right p {
  font-size: 1.3rem;
  white-space: pre-wrap;
}

div.profile-right p:last-of-type {
  margin-top: 1rem;
}

/* OPEN BUTTON - WHEN OUTSIDE LINK AVAILABLE */
section.profile div.profile-left a {
  font-family: "Manrope", sans-serif;
  font-size: 1.2rem;
  background-color: var(--bg-less);
  border: 1px solid var(--primary-color);
  border-radius: 8px;
  padding: 0.3rem 2rem 0.5rem 2rem;
  transition: ease-in-out 0.3s;
}

section.profile div.profile-left a:hover {
  transition: ease-in-out 0.3s;
  padding-right: 1.9rem;
  font-weight: 600;
  background-color: var(--primary-color);
  color: var(--bg-color);
  cursor: pointer;
}
