/* IMGS AT THE BOTTOM */
div.imgs-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2.5rem;
  width: 100%;
  background-color: var(--shadow-light);
  border-radius: 8px;
  padding: 3rem 2rem;
}

div.imgs-container a,
div.imgs-container video {
  flex: 1 1 600px;
}

div.imgs-container img,
div.imgs-container video {
  max-width: 100%;
}

div.imgs-container video:hover {
  cursor: pointer;
}

div.imgs-container a {
  transition: ease-in-out 0.3s;
}

div.imgs-container a:hover {
  opacity: 0.5;
  cursor: pointer;
  transition: ease-in-out 0.3s;
}
