/* PROJECT CARDS */
div.project-card {
  flex: 2;
  display: flex;
  flex-direction: column;
  text-wrap: wrap;
  padding: 1.5rem 2rem;
  max-width: 460px;
  min-width: 40%;
  background-color: var(--shadow);
}

/* TOP - TITLE + BUTTON */
div.project-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 1.2rem;
  gap: 0.8rem;
}

div.project-top button {
  min-width: fit-content;
  font-family: "Manrope", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  background-color: var(--bg-less);
  border: 2px solid var(--shadow);
  border-radius: 8px;
  padding: 0.5rem 1rem;
  transition: ease-in-out 0.3s;
}

div.project-top button:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: white;
  transition: ease-in-out 0.3s;
  cursor: pointer;
}

/* PROJECT TITLE */
div.project-card h2 {
  font-size: 1.8rem;
}

/* BORDER RADIUS PROJECT CARD + IMG + LANGLIST LINE */
div.project-card,
div.project-card img,
div.lang-used ul {
  border-radius: 8px;
}

/* LANG LIST */
div.lang-used ul {
  list-style: none;
  padding: 0.5rem 0;
  display: flex;
  justify-content: space-around;
  gap: 0.5rem;
  flex-wrap: wrap;
  background-color: var(--bg-less);
  margin: 0.5rem 0;
}

div.lang-used li {
  transition: ease-in-out 0.3s;
  font-size: 1.2rem;
}

div.lang-used li:hover {
  color: var(--primary-color);
  font-weight: 800;
  transition: ease-in-out 0.3s;
}

/* OBJECTIVE */
div.project-card p span {
  font-size: 1.15rem;
  font-weight: 600;
}
